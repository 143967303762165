<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="titleContainer bg-primary">
          <p class="titleText">
            {{
              method == "note"
                ? $t("labels.lookingInMyNotes")
                : $t("labels.lookingInMyDiaries")
            }}
          </p>
        </div>
        <div class="subContainer">
          <div
            v-for="(item, index) in getList()"
            :key="index"
            class="itemContainer"
          >
            <p class="itemDate">{{ getFormattedDate(item.updated_at) }}</p>
            <p class="itemContent">{{ item.entry_text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CustomHeader from "../components/CustomHeader.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "MyNotesPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      historiaImage: require("../assets/image/svg/history.svg"),
      lockImage: require("../assets/image/svg/personalisimo.svg"),
      searchText: "",
      method: "",
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    getFormattedDate(dt) {
      return moment(dt).format("DD-MM-YYYY");
    },
    getList() {
      let array = this.method == "note" ? this.notes : this.diaries;
      if (!array) return [];
      let filteredArray = [];
      if (!this.searchText) return array;
      for (let i = 0; i < array.length; i++) {
        let item = array[i];
        if (item.entry_text.toLowerCase().includes(this.searchText.toLowerCase())) {
          filteredArray.push(item);
        }
      }
      console.log(filteredArray);
      return filteredArray;
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
  computed: mapState({
    notes: (state) => state.main.notes,
    diaries: (state) => state.main.diaries,
  }),
  mounted() {
    // this.$store.dispatch('main/getNotes')
    const urlParams = new URLSearchParams(window.location.search);
    this.method = urlParams.get("method");
    this.searchText = urlParams.get("searchText");
    if (this.method == "note") {
      this.$store.dispatch("main/getNotesAttempt");
    } else {
      this.$store.dispatch("main/getDiariesAttempt");
    }
  },
};
</script>

<style scoped>
.itemContainer {
  margin: 10px 0px;
  width: 100%;
}
.itemDate {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.itemContent {
  color: black;
  font-size: 20px;
  width: 100%;
  flex-wrap: wrap;
  overflow-wrap: break-word;
}
</style>